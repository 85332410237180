import logo from "./logo.webp"

const clientData = {
    client_entity: 33,
    attorney_firm: 'Borowitz & Clark',
    attorney_name: 'Jeffrey Herman',
    attorney_number: '877-439-9717',
    attorney_email: 'jeffrey@jhermanlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_jeffrey_herman+(720p).mp4',
    mainColor: '#1F263A',
    secondaryColor: '#475E86',
    siteLink: 'https://jhermanlaw.com',
    logo
}

export default clientData